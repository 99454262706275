export default {
  moveCursor: (state, evt) => {
    const image = state.index.cursor

    //Find mouse pos
    const pos = window.app.utils.mouse.mousePositionDocument(evt)

    image.x = pos.x + 15
    image.y = pos.y + 15 - state.window.scroll.top.current
  },
  cycleImages: (data, state) => {
    const index = state.index.current_index + 1
    const display_length = data.index.backgrounds.length
    const cursors_length = data.index.cursors.length

    //Keeps images in sync
    if (index >= display_length || index >= cursors_length) {
      state.index.current_index = 0
    } else {
      state.index.current_index = index
    }
  },
  goImageUrl: (data, state) => {
    const current = state.index.current_index
    const url = data.index.urls[current]

    window.location = url
  },
}
