<template>
  <video ref="videoStream" loop muted playsinline autoplay class="video-stream" />
</template>

<script>
import Hls from 'hls.js'

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    posterUrl: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: null,
    },
    height: {
      type: Number,
      default: null,
    },
  },

  mounted() {
    if (Hls.isSupported()) {
      const video = this.$refs.videoStream
      const hls = new Hls()
      hls.loadSource(this.url)
      hls.attachMedia(video)
    }
  },
}
</script>

<style lang="scss" scoped>
.video-stream {
  width: 100%;
}
</style>
