<template>
  <div v-if="showFilter" class="collection-filter__component">
    <slot></slot>
    <div class="collection-filter__options">
      <CollectionFilterOption
        v-for="option in availableOptions"
        :key="option.name"
        :option="option"
        :active-sibling="openFilter"
        @toggle="updateOpenFilter"
        @update="updateFilter"
      />
    </div>
    <transition name="fade">
      <button v-if="filterActive" class="collection-filter__clear" @click="clearOptions">
        <span class="collection-filter__clear-text"> Clear </span>
      </button>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import CollectionFilterOption from './collection-filter-option.vue'

export default {
  components: {
    CollectionFilterOption,
  },

  props: {
    collectionHandle: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      openFilter: '',
      filteredOptions: {},
    }
  },

  computed: {
    ...mapGetters(['filterActive']),

    availableOptions() {
      return this.options.filter((option) => {
        if (option.name === 'Size') {
          if (this.collectionHandle === 'bags' || this.collectionHandle === 'objects') {
            return false
          }
        }

        return true
      })
    },
    showFilter() {
      return this.availableOptions.length > 0
    },
  },

  watch: {
    filteredOptions: {
      handler: function (newOptions) {
        this.updateFilterOptions(newOptions)
      },
      deep: true,
    },
  },

  methods: {
    ...mapMutations(['updateFilterOptions']),

    clearOptions() {
      this.openFilter = ''
      this.$children.forEach((child) => child.clearValues())
    },
    updateOpenFilter(toggledFilter) {
      if (toggledFilter.active) {
        this.openFilter = toggledFilter.name
      }
    },
    updateFilter(optionObject) {
      this.filteredOptions = { ...this.filteredOptions, ...optionObject }
    },
  },
}
</script>

<style lang="scss">
@import '~styles/frontend/variables';
@import '~styles/frontend/mixins';

.collection-filter {
  position: absolute;
  top: 0;
  left: 5px;
  bottom: 0;
  width: calc((3 / 23) * 100%);

  &__component {
    position: sticky;
    top: 75px;
    padding: 20px 10px;
  }

  &__heading {
    @include uppercase;
  }

  &__clear-text {
    @include animate-underline-v2(true);

    color: $accent;
    // text-decoration: underline;
  }

  @include respond-to('m') {
    position: relative;
    grid-column: 14 / -1;
    width: auto;
    padding-bottom: 20px;

    &__component {
      position: static;
      padding: 0;
    }

    &__clear {
      position: absolute;
      bottom: 5px;
    }
  }
}
</style>
