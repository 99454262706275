interface Main {
  data: any;
  state: any;
  view: any;
  mutator: any;
  event: any;
  store: any;
  bus: any;
  utils: any;
}

export default class Default {
  protected b: Main;
  protected d: any;
  protected s: any;
  protected v: any;
  protected m: any;
  protected e: any;
  protected ls: any;
  protected bus: any;
  protected u: any;

  constructor(main: Main) {
    this.b = main;

    this.d = main.data;
    this.s = main.state;
    this.v = main.view;
    this.m = main.mutator;
    this.e = main.event;
    this.ls = main.store;

    this.bus = main.bus;

    this.u = main.utils;

    this.init();
  }

  protected init(): void {}
}
