<template>
  <div class="togglable" :class="{ active }">
    <button
      aria-haspopup="true"
      :aria-expanded="active"
      class="togglable__button"
      @click="toggleActive"
    >
      <span class="togglable__button-text" :class="{ 'togglable__button-text_active': active }">
        {{ buttonText }}
      </span>
    </button>
    <div class="togglable__wrapper" :style="{ height: wrapperHeightStyle }">
      <transition name="togglable" @enter="setContentHeight" @leave="setContentHeight">
        <div v-show="active" class="togglable__content">
          <slot></slot>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Togglable',

  props: {
    name: {
      type: String,
      default: '',
    },
    initialState: {
      type: Boolean,
      default: false,
    },
    activeSibling: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      active: this.initialState,
      contentHeight: 0,
    }
  },

  computed: {
    wrapperHeightStyle() {
      return this.contentHeight > 0 ? `${this.contentHeight}px` : ''
    },
  },

  watch: {
    active(isActive) {
      this.$emit('toggle', {
        name: this.name,
        active: isActive,
      })
    },
    activeSibling(sibling) {
      if (sibling !== this.name) {
        this.active = false
      }
    },
  },

  methods: {
    toggleActive() {
      this.active = !this.active
    },
    setContentHeight(el) {
      this.contentHeight = this.active ? el.offsetHeight : 0
    },
  },
}
</script>

<style lang="scss" scoped>
.togglable__wrapper {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease-out;

  &__button {
    width: 100%;
    text-align: left;
    cursor: pointer;
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;

    &.togglable-enter-active,
    &.togglable-leave-active {
      transition: opacity 0.7s ease;
    }

    &.togglable-enter,
    &.togglable-leave-to {
      opacity: 0;
    }
  }
}
</style>
