<template>
  <Togglable
    :active-sibling="activeSibling"
    :button-text="option.name"
    :name="option.name"
    class="collection-filter-option"
    @toggle="$emit('toggle', $event)"
  >
    <ul class="collection-filter-option__values">
      <li
        v-for="value in splitValues(option.values)"
        :key="value"
        class="collection-filter-option__value"
      >
        <button class="collection-filter-option__button" @click="toggleValue(value)">
          <span
            class="collection-filter-option__button-text"
            :class="{
              'collection-filter-option__button-text_selected': isSelected(value),
            }"
          >
            {{ value }}
          </span>
        </button>
      </li>
    </ul>
  </Togglable>
</template>

<script>
import Togglable from '../common/togglable.vue'

export default {
  components: {
    Togglable,
  },

  props: {
    activeSibling: {
      type: String,
      default: '',
    },
    option: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selectedValues: [],
    }
  },

  watch: {
    selectedValues(newValues) {
      this.$emit('update', { [this.option.name]: newValues })
    },
  },

  methods: {
    clearValues() {
      this.selectedValues = []
    },
    isSelected(value) {
      return this.selectedValues.includes(value)
    },
    splitValues(valueString) {
      return valueString.split(',').map((value) => value.trim())
    },
    toggleValue(value) {
      this.selectedValues = this.isSelected(value)
        ? this.selectedValues.filter((selectedValue) => selectedValue !== value)
        : this.selectedValues.concat(value)
    },
  },
}
</script>

<style lang="scss">
@import '~styles/frontend/variables';
@import '~styles/frontend/mixins';

.collection-filter-option {
  // position: absolute;
  // top: 0;
  // left: 0;
  // bottom: 0;

  &__values {
    padding: 10px 0;
  }

  &__value {
    margin-bottom: 3px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__button-text {
    position: relative;
    padding-left: 10px;
    transition: all 0.2s ease;

    &:before {
      content: '▪';
      position: absolute;
      left: 0;
      opacity: 0;
    }

    &:hover {
      color: $accent;
    }

    &_selected {
      &:before {
        opacity: 1;
      }
    }
  }

  .togglable {
    &__button {
      position: relative;
      padding-right: 1em;
    }

    &__button-text {
      &:after {
        content: '+';
        position: absolute;
        top: 0;
        right: 0;
      }

      &_active {
        &:after {
          content: '–';
        }
      }
    }
  }
}
</style>
