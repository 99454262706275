import Vuex from 'vuex'
import Vue from 'vue'

// import cart from "./cart";
// import collections from "./collections";
// import overlay from "./overlay";
Vue.use(Vuex)

// const vuexLocal = new VuexPersistence({
//   storage: window.sessionStorage || {}
// });

const store = new Vuex.Store({
  modules: {
    // cart,
    // collections,
    // overlay
  },

  state: {
    cartError: '',
    showBanner: false,
    productOptions: [],
    filterOptions: {},
  },

  getters: {
    filterActive: (state) => {
      return !!Object.values(state.filterOptions).flat().length
    },
    currentCurrency: () => window.Shopify.currency.active,
  },

  mutations: {
    setBanner(state, bool) {
      state.showBanner = bool
    },
    setCartError(state, message) {
      state.cartError = message
    },
    updateFilterOptions(state, options) {
      state.filterOptions = options
    },
    updateProductOptions(state, options) {
      state.productOptions = options
    },
  },

  strict: process.env.NODE_ENV !== 'production',
  // plugins: [vuexLocal.plugin]
})

export default store
