import Base from '../../base'

export default class Default extends Base {
  init() {
    this.bus.$on('overlay/open', (d) => {
      this.open(d)
    })
    this.bus.$on('overlay/close', (d) => {
      this.close(d)
    })
    this.bus.$on('overlay/toggle', (d) => {
      this.toggle(d)
    })
  }

  open(path) {
    this.s.overlay.current = path
  }

  close(path) {
    this.s.overlay.current = ''
  }

  toggle(path) {
    if (this.s.overlay.current != path) {
      this.s.overlay.current = path
    } else {
      this.s.overlay.current = ''
    }
  }
}
