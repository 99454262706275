<template>
  <component :is="tag" class="intersection-wrapper">
    <slot :intersected="intersected" />
  </component>
</template>

<script>
// TODO: key observer?

export default {
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    threshold: {
      type: Array,
      default: () => [0, 1],
    },
    rootMargin: {
      type: String,
      default: '0px',
    },
    debug: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      wrapperObserver: null,
      intersected: false,
    }
  },

  created() {
    this.wrapperObserver = new IntersectionObserver(
      (entries) => {
        if (this.debug) {
          console.log('Intersect', entries[0])
        }
        if (entries[0].isIntersecting) {
          this.intersected = true
          this.wrapperObserver.disconnect()
        }
      },
      {
        threshold: this.threshold,
        rootMargin: this.rootMargin,
      }
    )
  },

  mounted() {
    this.wrapperObserver.observe(this.$el)
  },

  unmounted() {
    this.wrapperObserver.disconnect()
  },
}
</script>
