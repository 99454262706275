import Vue from 'vue'
import _ from 'lodash'
import $ from 'jquery'

export default class Default {
  constructor() {
    Vue.component('OverlayGallery', {
      props: ['slides', 'overlay', 'dimensions'],
      data: function () {
        return {
          ready: false,
          active: false,
          $overlay: -1,
          $slides: -1,
          current_slide: 0,
          scroll_top: 0,
          tops: [0],
        }
      },
      mounted: function () {
        this.init()
      },
      methods: {
        init: function () {
          this.ready = true
        },
        activate: function () {
          //Monitor scroll
          this.$overlay = $('.overlay.overlay-gallery')

          this.$overlay.on('scroll', (e) => {
            this.scroll_top = this.$overlay.scrollTop()
          })

          this.$slides = this.$overlay.find('.slide')
          this.tops = _.reverse(
            _.map(this.$slides, ($slide) => {
              return $($slide).offset().top
            })
          )

          this.active = true
        },
        closeOverlay: function () {
          this.active = false
          this.$emit('update:overlay', '')
        },
        showImage: (url, format) => {
          //Split url by '.'
          const dot_pos = (~-url.lastIndexOf('.') >>> 0) + 1
          // Insert format in string
          return [url.slice(0, dot_pos), `_${format}`, url.slice(dot_pos)].join('')
        },
        getActive: function () {
          //Run activate function if we need to
          if (this.ready == true && this.overlay == 'gallery') {
            if (!this.active) {
              setTimeout(() => {
                this.activate()
              }, 10)
            }

            return true
          }

          return false
        },
        pager: function () {
          if (!this.getActive()) return ''

          //find current slide
          const scroll_top = this.scroll_top
          let current_slide =
            this.tops.length -
            _.findIndex(this.tops, (v) => {
              return v < scroll_top
            })

          if (current_slide > this.tops.length) current_slide = 1

          return `VIEW ${current_slide}/${this.slides.length}`
        },
      },
      template: `
        <div class="overlay overlay-gallery" v-if="getActive()" >
          <div class="pager" v-text="pager()"></div>
          <div class="close" v-on:click="closeOverlay()">
            <div class="inner">
              <div class="slide" v-for="(slide, slide_idx) in slides">
                <img v-bind:src="showImage(slide.url, dimensions)" alt="" />
              </div>
            </div>
          </div>
        </div>
      `,
    })
  }
}
