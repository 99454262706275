import Vue from 'vue'
import $ from 'jquery'
import _ from 'lodash'

export default {
  setMenu: (state, key = '') => {
    //Close menu if already open
    if (state.header.menu == key) {
      state.header.menu = ''
      return
    }

    //Set key
    state.header.menu = key
  },
  toggleSubmenu: (state, key, toggle = -1) => {
    const existing = state.header.existing_submenu
    const submenu = state.header.current_submenu

    key = key.replace('submenu-', '')

    if (toggle == true) {
      state.header.current_submenu = key
    } else {
      state.header.current_submenu = existing ? existing : false
    }
  },
  toggleCurrency: (state, mutator) => {
    //Disable search to prevent overlap
    mutator.app.toggle(state, 'search', false)

    //Show currency
    mutator.app.toggle(state, 'currency', true)
  },
  toggleSearch: (state, mutator) => {
    mutator.app.toggle(state, 'search', true)

    //Focus search input
    Vue.nextTick(() => {
      const $input = $('#search')
      $input.focus()
    })
  },
}
