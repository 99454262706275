<template>
  <div class="collection-block__component">
    <slot :count="count" :filtered-count="filteredCount"></slot>
    <!-- <div
      v-if="filteredProducts.length"
      class="collection-block__products"
    > -->
    <!-- </div> -->
    <div v-if="filteredProducts.length" class="collection-block__products">
      <transition-group name="products" tag="div" class="collection-block__products-inner">
        <ProductBlock v-for="product in filteredProducts" :key="product.id" :product="product" />
      </transition-group>
    </div>
    <div v-else class="collection-block__empty">
      <transition name="fade" mode="out-in">
        <span v-if="filterActive"> No matches </span>
      </transition>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { uniq } from 'ramda'
import vueScrollTo from 'vue-scrollto'
import { mapGetters, mapMutations, mapState } from 'vuex'
import ProductBlock from './product-block.vue'

const reduceToOptions = (products) =>
  uniq(
    products.reduce(
      (options, product) =>
        options.concat(
          ...product.variants.map((variant) => [variant.option1, variant.option2, variant.option3])
        ),
      []
    )
  )

export default {
  components: {
    ProductBlock,
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
    handle: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      page: 1,
      products: [],
      error: '',
      resizeObserver: null,
    }
  },

  computed: {
    ...mapGetters(['filterActive']),
    ...mapState(['filterOptions']),

    filteredProducts() {
      const filterKeys = Object.keys(this.filterOptions)

      return this.products.filter((product) =>
        filterKeys.every((key) => {
          const filterValues = this.filterOptions[key]
          const productOption = product.options.find((option) => option.name === key)
          if (!productOption && filterValues.length) return false
          if (!filterValues.length) return true
          return this.filterOptions[key].some((option) =>
            product.variants.find(
              (variant) =>
                variant.available && variant[`option${productOption.position}`].includes(option)
            )
          )
        })
      )
    },
    filteredCount() {
      return this.filteredProducts.length
    },
  },

  watch: {
    products(newProducts) {
      // this.$emit('loaded', reduceToOptions(newProducts))
      this.updateProductOptions(reduceToOptions(newProducts))
    },
  },

  async created() {
    const storedProducts = sessionStorage.getItem(this.handle)

    if ('resizeObserver' in window) {
      this.resizeObserver = new ResizeObserver((entries) => {
        const blockEntry = entries[0]
        const blockClearance = this.$el.offsetTop + blockEntry.contentRect.height
        const viewportScroll = window.scrollY + window.innerHeight

        if (this.products.length && viewportScroll > blockClearance) {
          vueScrollTo.scrollTo(this.$el, {
            offset: -1 * (document.querySelector('.header').offsetHeight + 19),
          })
        }
      })
    }

    if (storedProducts) {
      this.products = JSON.parse(storedProducts)
    } else {
      while (this.products.length < this.count) {
        try {
          const response = await axios.get(
            `/collections/${this.handle}/products.json?page=${this.page}`
          )
          this.products = this.products.concat(...response.data.products)
          this.page += 1
        } catch (exception) {
          break
        }
      }

      sessionStorage.setItem(this.handle, JSON.stringify(this.products))
    }
  },

  async mounted() {
    console.log('mounted!')
    if ('resizeObserver' in window) {
      this.resizeObserver.observe(this.$el)
    }
  },

  methods: {
    ...mapMutations(['updateProductOptions']),
  },
}
</script>

<style lang="scss">
@import '~styles/frontend/variables';
@import '~styles/frontend/mixins';
@import '~styles/frontend/functions';

.collection-block {
  --products-columns: 18;
  --block-margin: calc((1 / var(--products-columns)) * 100%);

  position: relative;
  min-height: 100vh;
  border-top: 1px solid;

  &__heading {
    display: grid;
    grid-template: 1fr / repeat(23, 1fr);
    grid-gap: 5px;
    margin-bottom: 60px;
    padding: 20px $inset 0;
  }

  &__title {
    @include cx-uc();

    grid-column: 4 / auto;
  }

  &__count {
    grid-column: 7 / -1;
  }

  &__products,
  &__empty {
    width: calc((var(--products-columns) / 23) * 100%);
    margin: 0 auto;
    padding: 0 $inset;
  }

  &__products-inner {
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }

  .product-block {
    width: 50%;
    // width: calc(50% - (var(--block-margin) / 2));
    // transition: opacity 0.8s ease, transform 0.8s ease 1s;
    transition: all 0.3s ease;
    padding: 0 calc((0.25 / 9) * 100%);
    // background: red;

    // &:nth-child(2n + 1) {
    //   margin-right: var(--block-margin);
    // }

    // &.
    &__inner {
      @include respond-to(m) {
        width: 100%;

        &__inner {
          margin: 0 calcPercentage($columns_mob, 1);
        }
      }
    }

    &.products-enter,
    &.products-leave-to {
      opacity: 0;
      // transform: translateY(30px);
    }

    // &.products-enter-active,
    &.products-leave-active {
      position: absolute;
      // top: 0;
      // background: red;
    }

    &.products-move {
      opacity: 0;
      transition: opacity 0.3s ease, transform 0.1s ease 0.3s;
    }
  }

  @include respond-to('m') {
    &__products,
    &__empty {
      width: 100%;
    }

    &__heading {
      position: sticky;
      top: calc(#{$header_height_mob} - 1px);
      z-index: 100;
      background: $background;
      margin-bottom: 0;
      // padding-bottom: 20px;
      border-bottom: 1px solid;
    }

    &__title {
      grid-column: 1 / 6;
    }

    &__count {
      grid-column: 6 / 13;
    }

    .product-block {
      width: 100%;

      padding: 0;
    }
  }
}

// .products-enter, .products-leave-to {
//   opacity: 0;

//   // .product-block__inner {
//   //   transform: translateY(15px);
//   // }
// }

// .products-enter-active {
//   transition:
//     opacity 2s ease,
//     transform 2s ease
//   ;
// }

// .products-leave-active {
//   position: absolute;
// }
</style>
