import _ from 'lodash'

export default {
  hasOptions: (data) => {
    return data.product.variants.length > 1
  },
  formatOptions: (data, state) => {
    const product = Object.keys(state.product.selected_product).length
      ? state.product.selected_product
      : data.product

    const optionsWithValues = product.options.reduce(
      (options, option, optionIndex) => [
        ...options,
        {
          type: option,
          type_index: optionIndex,
          values: product.variants
            .map((variant) => variant.options[optionIndex])
            .filter((value, i, self) => self.indexOf(value) === i)
            .map((value) => ({
              value,
              available: product.variants.some(
                (variant) => variant.available && variant.options[optionIndex] === value
              ),
            })),
        },
      ],
      []
    )
    return optionsWithValues
  },
  formatProductGroupOptions(product_group) {
    return product_group.map((product) => product.variants[0].options[0])
  },
  showOptionClass: (state, option, option_value) => {
    const ot_index = option.type_index
    const selected_options = state.product.selected_options

    return selected_options[ot_index] && selected_options[ot_index] == option_value ? 'active' : ''
  },
  colorCount: (data) => {
    const colours = _.filter(data.product.variants, (v) => {
      return v.available
    })

    return colours && colours.length > 1 ? `Available in ${colours.length} colors` : ''
  },
}
