<template>
  <img
    class="image-loader"
    :class="{ 'image-loader_loaded': loaded }"
    :src="imgSrc || placeholderSrc"
    :alt="alt"
    :draggable="draggable"
    :loading="eager"
    @load="setLoaded"
  />
</template>

<script>
// import lazyLoad from '~/mixins/lazyLoad'

export default {
  // mixins: [lazyLoad],

  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: null,
    },
    height: {
      type: Number,
      default: null,
    },
    draggable: {
      type: Boolean,
      default: true,
    },
    eager: {
      type: Boolean,
      default: false,
    },
    canLoad: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      loaded: false,
      imgSrc: null,
    }
  },

  computed: {
    placeholderSrc() {
      return this.width && this.height
        ? `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${this.width} ${this.height}"%3E%3C/svg%3E`
        : ''
    },
  },

  watch: {
    canLoad() {
      this.setLoaded()
    },
    shouldLoad() {
      this.setLoaded()
    },
  },

  mounted() {
    this.setLoaded()
  },

  methods: {
    setLoaded() {
      // if (this.canLoad && this.shouldLoad) {
      if (this.canLoad) {
        const img = new Image()
        img.src = this.src
        img.onload = () => {
          this.imgSrc = this.src
          this.loaded = true
          this.$emit('loaded')
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.image-loader {
  opacity: 0;
  transition: opacity 0.3s ease;

  &_loaded {
    opacity: 1;
  }
}
</style>
