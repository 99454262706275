import currency from 'currency.js'

const getCurrencySymbol = (code) => {
  if (code == 'GBP') return '£'
  if (code == 'EUR') return '€'
  if (code == 'JPY') return '¥'
  if (code == 'CNY') return '¥'
  if (code == 'SEK') return 'kr'
  if (code == 'DKK') return 'kr'
  if (code == 'NOK') return 'kr'
  if (code == 'CHF') return 'CHF'
  if (code == 'INR') return '₹'
  return '$'
}

export const showMoney = (priceInCents) => {
  const currencyCode = window._data.currency

  const options = {
    precision: 2,
    formatWithSymbol: true,
    // pattern: '# !',
    separator: ',',
    decimal: '.',
    symbol: getCurrencySymbol(currencyCode),
  }

  const price = currency(priceInCents / 100, { ...options, currency: currencyCode })
  const formatted = price.format()

  // Remove .00 if present
  const withoutZeroCents = formatted.replace(/\.00([^\d]*)$/, '$1')

  // Add currency code for non-USD
  return currencyCode === 'USD' ? withoutZeroCents : `${withoutZeroCents} ${currencyCode}`
}
export const showPrice = (data) => {
  let formatted_original = false
  const formatted_price = showMoney(data.price)

  if (data.compare_at_price != null && data.compare_at_price > data.price) {
    formatted_original = showMoney(data.compare_at_price)
  }

  if (formatted_original) {
    return `<span class="sale">${formatted_price}</span> <span class="original">${formatted_original}</span>`
  }

  return formatted_price
}

export const showCurrency = () => {
  // const code = window._data.currency
  // return `${window._data.currency?.format} ${code}`
  return ''
}

export default { showMoney, showPrice, showCurrency }
