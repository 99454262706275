<template>
  <div class="product-block">
    <keep-alive>
      <div v-if="product.is_blank" class="inner">
        <div class="blank"></div>
      </div>
      <div v-else class="inner">
        <a :href="'/products/' + product.handle" class="link" :aria-label="product.title"></a>
        <div class="gallery">
          <div class="image">
            <image-loader
              :src="resizeImage(images[0], imageFormat)"
              :alt="product.title"
              :width="750"
              :height="1000"
            />
          </div>
          <div class="image image_mood">
            <image-loader
              v-if="images[1]"
              :src="resizeImage(images[1], imageFormat)"
              :alt="product.title"
            />
          </div>
        </div>
        <div class="caption">
          <h2 class="heading">
            <product-title :break="false" :title="product.title" class="title" />
          </h2>
          <div class="prices">
            <PriceDisplay :price="Number(price)" class="price" />
            <PriceDisplay
              v-if="originalPrice"
              :price="Number(originalPrice)"
              class="price price_original"
            />
          </div>
          <ul class="sizes">
            <li
              v-for="size in sizes"
              :key="size.name"
              class="size"
              :class="{ size_unavailable: !size.available }"
            >
              {{ size.name }}
            </li>
          </ul>
          <div v-if="preorder" class="preorder">PRE-ORDER</div>
        </div>
      </div>
    </keep-alive>
  </div>
</template>

<script>
import PriceDisplay from '../helpers/price-display.vue'
import { resizeImage } from '../../lib/shopify.js'

const PREORDER_TAGS = ['Preorder', 'Product Preorder']

const formatPrice = (price) => (typeof price === 'string' ? price : price / 100)
const getOptionName = (variant, optionIndex) =>
  variant[`option${optionIndex + 1}`] || variant.options[optionIndex]

export default {
  components: {
    PriceDisplay,
  },

  props: {
    product: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      imageFormat: '750x1000_crop_center',
    }
  },

  computed: {
    resizeImage() {
      return resizeImage
    },
    images() {
      return this.product.images.map((image) => {
        return typeof image === 'string' ? image : image.src
      })
    },
    originalPrice() {
      const saleVariants = this.product.variants.filter((variant) => variant.compare_at_price)
      if (!saleVariants.length) return null
      return formatPrice(saleVariants[0].compare_at_price)
    },
    preorder() {
      return PREORDER_TAGS.some((tag) => this.product.tags.includes(tag))
    },
    price() {
      const variants = [...this.product.variants].sort()
      const price = variants?.[0].price
      return formatPrice(price)
    },
    sizes() {
      // TODO: should also work with liquid object
      const optionIndex = this.product.options.findIndex(
        (option) => option === 'Size' || option.name === 'Size'
      )
      if (optionIndex === -1) return []
      return this.variants
        .map((variant) => {
          const name = getOptionName(variant, optionIndex)
          const available = this.variants.some(
            (_variant) => getOptionName(_variant, optionIndex) === name && _variant.available
          )
          return {
            available,
            name,
          }
        })
        .filter((variant, index, self) => index === self.findIndex((v) => v.name === variant.name))
    },
    variants() {
      return this.product.variants
    },
  },

  methods: {
    formatTitle(title) {
      const splitTitle = title.split(' in ')
      return splitTitle[1]
        ? `<span class="name">${splitTitle[0]}</span> in <span class="variant">${splitTitle[1]}</span>`
        : `<span class="name">${title}</span>`
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~styles/frontend/variables';
@import '~styles/frontend/mixins';
@import '~styles/frontend/functions';

.product-block {
  margin-bottom: 40px;

  @include respond-to(m) {
    width: 100%;

    .caption {
      justify-content: space-between;
    }

    .heading {
      flex: 1;
      width: auto;
      padding-right: 10px;
    }
  }
}

.inner {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.link {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.gallery {
  position: relative;
  width: 100%;
  margin-bottom: 5px;
  padding-top: 133.3%;
  overflow: hidden;
}

.image {
  position: absolute !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0 !important;
  transition: opacity 0.3s ease-out;
}

.image_mood {
  background-size: cover;
  background-position: center;
  z-index: 2;
  @include respond-to(touch) {
    display: none;
  }
}

.caption {
  display: grid !important;
  width: 100%;
  grid-template-columns: 1fr auto;
  grid-auto-rows: auto;
  column-gap: 10px;
  row-gap: 4px;
  padding-top: 10px;
}

.heading {
  flex: 1;
  padding-right: $gutter;
}

.title {
  .name {
    @include cx-uc();
  }

  .variant {
    text-transform: capitalize;
  }
}

.sizes {
  display: flex;
  flex-wrap: wrap;
  transition: opacity 0.25s ease;
}

.size {
  position: relative;
}

.size {
  &:not(:last-child) {
    margin-right: 1em;
  }
}

.size_unavailable {
  &:after {
    content: '';
    position: absolute;
    top: calc(50% - 1px);
    left: -20%;
    width: 140%;
    height: 1px;
    background: $dark;
  }
}

.prices {
  justify-self: flex-end;
}

.price_original {
  text-decoration: line-through;
}

@media (hover: hover) {
  .inner:not(:hover) {
    .image_mood,
    .sizes {
      opacity: 0;
    }
  }
}
</style>
