import Base from '../../base'
import Vue from 'vue'
import $ from 'jquery'
import _ from 'lodash'

export default class Default extends Base {
  init() {
    if (this.d.template.indexOf('article-lookbooks') == -1) return
    //Bail unless we are on product page

    //Preload the images
    this.initCounter()

    this.bus.$on('vue/loaded', () => this.initCounter())
    this.bus.$on('window/resize', () => {
      setTimeout(() => this.initCounter(), 100)
    })
  }

  initCounter() {
    const content = document.getElementById('content')
    if (content) {
      const images = content.getElementsByTagName('img')
      const urls = _.map(images, (v) => v.getAttribute('src'))

      window.app.u.preload
        .files({
          src: urls,
          callback: () => {
            this.startCounter()
          },
        })
        .start()
    }
  }

  startCounter() {
    //Get images again
    const content = document.getElementById('content')
    if (content) {
      const images = content.getElementsByTagName('img')
      this.s.lookbook.images = _.map(images, (v, idx) => {
        const rect = v.getBoundingClientRect()

        return {
          idx: idx,
          top: rect.top + this.s.window.scroll.top.current,
          height: rect.height,
        }
      })
    }
  }
}
