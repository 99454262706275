export default {
  setOverlay: (state, overlay = '') => {
    state.overlay.previous = state.overlay.current
    state.overlay.current = overlay
  },
  toggleOverlay: (state, overlay = '') => {
    state.overlay.previous = state.overlay.current
    state.overlay.current = overlay === state.overlay.previous ? '' : overlay
  },
  toggleMMenuShop: (state) => {
    state.overlay.mmenu.shop_active = !state.overlay.mmenu.shop_active
  },
  hideAlert: (state, store) => {
    state.overlay.browser_alert = false
    localStorage.setItem('alertClosed', true)
  },
}
