<template>
  <transition>
    <div class="Banner" v-if="enabled && showBanner">
      <button class="Banner-Close" @click="close">Close</button>
      <div class="Banner-Content" v-html="content"></div>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    enabled: {
      type: Boolean,
      default: false,
    },

    content: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapState(['showBanner']),
  },

  methods: {
    close() {
      this.$store.commit('setBanner', false)
      sessionStorage.setItem('bannerClosed', true)
    },
  },

  mounted() {
    if (!sessionStorage.getItem('bannerClosed')) {
      this.$store.commit('setBanner', true)
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~styles/frontend/variables';

.Banner {
  // position: fixed;
  // bottom: 0;
  // left: 0;
  // right: 0;
  position: relative;
  padding: 7px 10%;
  text-align: center;
  background-color: #fffffe;
  border-top: 1px solid;
  z-index: 1200;

  &-Close {
    position: absolute;
    top: 8px;
    right: calc((((100% - #{$inset * 2}) / 23) * 2) + #{$inset});
    transform: translateX(100%);
    font-size: 11px;
    text-align: center;
    text-transform: uppercase;
  }

  @media (max-width: 700px) {
    padding-left: $inset;
    padding-right: $inset * 4;
    text-align: left;

    &-Close {
      right: $inset;
      transform: translateX(0);
    }
  }
}
</style>
