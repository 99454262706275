export default {
  hasItems: (data) => {
    return data.cart.items.length > 0
  },
  showItemCount: (data) => {
    return data.cart.item_count || 0
  },
  showItemPrice: (s, v, li) => {
    return v.currency.showMoney(li.price)
  },
  showItemTotal: (s, v, li) => {
    return v.currency.showMoney(li.line_price)
  },
  showSize(data, item_index) {
    const item = data.cart.items[item_index]
    if (!item || !item.product) return
    const size_index = item.product.options.indexOf('Size')
    if (size_index === -1) return
    return item.variant.options[size_index]
  },
}
