<template>
  <div class="product-title" v-html="titleHtml" />
</template>

<script>
export default {
  props: {
    break: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
  },

  computed: {
    titleHtml() {
      return this.title.replace(
        /\b(by|in)\b/gi,
        `${this.break ? '<br>' : ''}<span class="product-title__lc">$1</span>`
      )
    },
  },
}
</script>

<style lang="scss">
.product-title {
  text-transform: uppercase;

  &__lc {
    text-transform: lowercase;
  }
}
</style>
