import Products from './shopify/products'
import Cart from './shopify/cart'

export default class Shopify {
  constructor(main) {
    this._ajax_defaults = {
      headers: {
        //'X-Spree-Token' : Spree.api_key
      },
      dataType: 'json',
      contentType: 'application/json',
      error: (d) => {
        console.log(d)
      },
    }

    this.products = new Products(main, this)
    this.cart = new Cart(main, this)
  }
}
