import Vue from 'vue'
import _ from 'lodash'
import $ from 'jquery'

export default class Default {
  constructor() {
    Vue.component('LazyImage', {
      props: {
        queue: Array,
        window: Object,
        src: String,
        size: String,
        className: {
          type: String,
          default: '',
        },
      },
      data() {
        return {
          ready: false,
          loaded: false,
          visible: false,
        }
      },
      mounted() {
        this.init()
      },
      methods: {
        init() {
          this.ready = true
        },
        getSrc(url, format) {
          //Split url by '.'
          const dot_pos = (~-url.lastIndexOf('.') >>> 0) + 1
          // Insert format in string
          return [url.slice(0, dot_pos), `_${format}`, url.slice(dot_pos)].join('')
        },
        showImage(ready) {
          if (!ready) return false
          if (this.visible) return this.getSrc(this.src, this.size)

          const $el = $(this.$el)
          const queue = this.queue
          const el_top = $el.offset().top
          const scroll_top = this.window.scroll.top.current
          const window_height = this.window.resize.height.current
          const lookahead = scroll_top + 2 * window_height
          const lookbehind = scroll_top - 2 * window_height
          const src = this.getSrc(this.src, this.size)

          const image = new Image()
          image.onload = () => (this.loaded = true)
          image.src = src

          if (el_top < lookahead && el_top > lookbehind) {
            this.visible = true
            return src
          }

          return false
        },
      },
      template: /*html*/ `
        <img
          :src="showImage(ready)"
          :class="[ className, { active: loaded && visible } ]"
          class="lazy-image"
        />
      `,
    })
  }
}
