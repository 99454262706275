import Options from './product/options'
import Buy from './product/buy'
import { showPrice } from './currency'

import _ from 'lodash'

const Product = {
  showTitle({ title = '' } = {}) {
    return title.replace(/(\sin|by\s)/gi, '<br><span class="lowercase">$&</span>')
    // .split(/(\sin|by\s)/i)
    // .map((titleFragment, i) => {
    //   if (i === 0)
    //     return `<span class="name">${titleFragment}</span>`;
    //   if (i === 2)
    //     return `<span class="variant">${titleFragment}</span>`;
    //   return titleFragment;
    // })
    // .join('');
  },
  showTotal: (state) => {
    const total =
      parseInt(state.product.selected_variant.price) * parseInt(state.product.selected_quantity)

    return showPrice({
      price: total,
    })
  },
  isAvailable: (variant) => {
    return !!variant.available
  },
  showPrice: (p) => {
    return showPrice({
      price: p.price,
      original: p.compare_at_price,
    })
  },
  showNote: (p) => {
    let message = false

    const is_low = !!_.find(p.variants, (v) => {
      return (
        v.inventory_management == 'shopify' &&
        v.inventory_policy == 'deny' &&
        v.inventory_quantity <= 3 &&
        v.inventory_quantity > 0
      )
    })

    if (p.compare_at_price > p.price) message = 'On Sale'
    if (!p.available) message = 'Sold Out'
    if (is_low) message = 'Low in Stock'

    return message ? message : ''
  },
  hasSalePrice: (state, view, variant) => {
    const price = variant.price
    const original = variant.compare_at_price
    return original != null && original > price
  },
  showNotify: (data, state) => {
    const product = data.product
    const variant = state.product.selected_variant
    const available = variant.available
    const is_notifiable = _.includes(product.tags, 'notifiable')

    return is_notifiable && !available ? true : false
  },
  isNotified: (state) => {
    const get = []
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (a, name, value) {
      get[name] = value
    })

    return !!get['contact_posted']
  },

  showGalleryCaption: (d, s) => {
    const offset_top = s.window.scroll.top.current
    const window_height = s.window.resize.height.current

    const images = _.clone(s.product.images).reverse()

    const image = _.find(images, (v) => {
      const window_half = offset_top + window_height / 2
      // var image_half  = v.top + (v.height/2);
      return window_half > v.top
    })

    if (!image) return ''

    const title = image.caption
    const total = d.product.images.length + 1

    return `${image.index}/${total} ${title}`
  },
  showContentClass: (s) => {
    const product_bottom = s.product.product_bottom
    const content_bottom = s.product.content_bottom
    const window_bottom =
      s.window.scroll.top.current +
      (s.window.resize.height.current - (s.window.resize.height.current - product_bottom))
    const sticky_end = window_bottom > content_bottom
    const sticky_disable = product_bottom > s.window.resize.height.current

    return {
      'sticky-end': sticky_end,
      'sticky-disable': sticky_disable,
    }
  },
  getBuyAvailability: (d, s) => {
    return s.product.selected_variant.available
  },
  showGallery(state) {
    return state.product.selected_product.images
  },
  formatSlideshow(s, images, opts_switch = '') {
    return _.chain(images)
      .map((img, idx) => {
        return {
          alt: img.alt,
          url: img.src,
          width: img.width,
          height: img.height,
          class: s.product.selected_slide == idx ? 'active' : '',
        }
      })
      .value()
  },
}

export default Object.assign({}, Product, Buy, Options)
