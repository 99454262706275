import Vue from 'vue'
import $ from 'jquery'
import Hammer from 'hammerjs'
import _ from 'lodash'
export default class Default {
  constructor(_main) {
    Vue.component('Slideshow', {
      props: ['slides'],
      data: function () {
        return {
          pan_offset: 0,
          width: 0,
          selected: 0,
        }
      },
      mounted: function () {
        //Preload slides
        const preload = _main.utils.preload
        const images = _.map(this.slides, (v) => {
          return v.url
        })
        preload.files({
          src: images,
          callback: () => {},
        })

        //Set width
        this.width = $(this.$el).width()
        window.addEventListener('resize', () => {
          this.width = $(this.$el).width()
        })

        //Handle swiping
        const sliderEl = this.$refs.sliderSwipe
        const hm = new Hammer(sliderEl)

        hm.on('panstart', (ev) => {
          this.panStart(ev)
        })

        hm.on('panmove', (ev) => {
          this.panMove(ev)
        })

        hm.on('panend', (ev) => {
          this.panEnd(ev)
        })
      },
      methods: {
        panStart: function (ev) {
          // console.log(ev.distance);
          this.pan_offset = 0
        },
        panMove: function (ev) {
          //Max drag as ratio
          const max = 0.6

          //Convert delta X to an -1 - 1 number
          let ratio = ev.deltaX / this.width

          //Constrain the ratio to -0.5 to 0.5;
          if (ratio < -max) ratio = -max
          if (ratio > max) ratio = max

          //Easing should slow the further you drag
          const ease = function (t) {
            return t * (2 - t)
          }
          const abs = Math.abs(ratio) * (1 / max) //ease(Math.abs(ratio) * 2);
          ratio = ratio > 0 ? abs / (1 / max) : -abs / (1 / max)

          //Convert back from ratio to a number
          const offset = ratio * this.width

          this.pan_offset = offset

          //Take over scroll if you have gone too far?
        },
        panEnd: function (ev) {
          //If we have panned further than X, pan to next slide and set selected slide
          const swipe_distance = 50 //this.width / 6; //Lets use what feels normal to the finger
          if (Math.abs(this.pan_offset) > swipe_distance) {
            if (this.pan_offset < 0) {
              this.nextSlide()
            } else {
              this.prevSlide()
            }
          } else {
            this.resetSlide()
          }
        },
        nextSlide: function () {
          //Difference between current offset and width
          const start = this.pan_offset
          const end = -this.width
          this.animateOffset(start, end, () => {
            //Set slide and revert pan offset
            this.pan_offset = 0
            const select = this.selected + 1 >= this.slides.length ? 0 : this.selected + 1
            this.selected = select
          })
        },
        prevSlide: function () {
          //Difference between current offset and width
          const start = this.pan_offset
          const end = this.width
          this.animateOffset(start, end, () => {
            //Set slide and revert pan offset
            this.pan_offset = 0
            const select = this.selected - 1 < 0 ? this.slides.length - 1 : this.selected - 1
            this.selected = select
          })
        },
        resetSlide: function () {
          //Smoothly go back to 0
          const start = this.pan_offset
          const end = 0
          this.animateOffset(start, end, () => {
            this.pan_offset = 0
          })
        },

        setSlide: function (slide_idx) {
          //Animate from current slide to whichever slide we are going to
          const start = this.pan_offset
          const end = (this.selected - slide_idx) * this.width + this.pan_offset

          this.animateOffset(start, end, () => {
            this.selected = slide_idx
            this.pan_offset = 0
          })
        },
        animateOffset: function (start, end, cb) {
          //Convert offset to a sensible number of frames
          const frame_total = 20
          let frames = frame_total
          let active = true

          //Easing function
          const ease = function (t) {
            return t * (2 - t)
          }

          //Get distance as ratio
          const difference = end - start

          //Animate from start to end duraion frames
          const loop = (now) => {
            frames -= 1
            if (!!active) {
              //Get frames as ratio
              const animation_ratio = ease((frame_total - frames) / frame_total)
              const position = start + animation_ratio * difference

              this.pan_offset = position

              if (frames <= 0) {
                active = false
                cb()
              }

              requestAnimationFrame(loop)
            }
          }

          requestAnimationFrame(loop)
        },
        showImage: function (url, format) {
          //Format dimensions
          const dimensions = '800x1200_crop_center'
          const dot_pos = (~-url.lastIndexOf('.') >>> 0) + 1
          url = [url.slice(0, dot_pos), `_${dimensions}`, url.slice(dot_pos)].join('')

          return url
        },
        showSlideStyle: function (slide, slide_idx, selected) {
          const style = {
            'background-image': `url(${slide.url})`,
          }

          //Add offset to slide styling
          const offset = (slide_idx - selected) * this.width + this.pan_offset
          const transform = `translateX(${offset}px) `
          style.transform = transform

          //Slide translate offset is set by its index * width + its current drag
          return style
        },
        showHangingSlideStyle: function (slides, selected, start_or_end = 'start') {
          //The hanging slide is always selected - 1 unless -1 < 0
          let hanging_idx
          let offset

          if (start_or_end == 'start') {
            offset = -1 * this.width + this.pan_offset
            hanging_idx = selected - 1 >= 0 ? selected - 1 : slides.length - 1
          } else {
            offset = this.width + this.pan_offset
            hanging_idx = selected + 1 <= slides.length - 1 ? selected + 1 : 0
          }

          //Select slide
          const hanging_slide = slides[hanging_idx]

          //Background style
          const style = { 'background-image': `url(${hanging_slide && hanging_slide.url})` }

          //Add offset to slide styling
          const transform = `translateX(${offset}px) `
          style.transform = transform

          //Slide translate offset is set by its index * width + its current drag
          return style
        },
      },
      template: /*html*/ `
        <div class="slideshow" >
          <div class="slides">
            <div class="controls">
              <div class="touch" ref="sliderSwipe"></div>
            </div>
            <div class="slide" v-bind:style="showHangingSlideStyle(slides,selected, 'start')"></div>
            <div class="slide" v-for="(slide, slide_idx) in slides" v-bind:style="showSlideStyle(slide,slide_idx,selected)"></div>
            <div class="slide" v-bind:style="showHangingSlideStyle(slides,selected, 'end')"></div>
          </div>
          <div class="pager">
            <nav class="page">
              <ul>
                <li v-for="(slide, slide_idx) in slides"  v-on:click="setSlide(slide_idx)" v-bind:class="{active: slide_idx == selected}">
                  <span></span>
                </li>
              </ul>
            </nav>
          </div>
          <div class="caption">
            <div class="counter">
              Image {{ selected + 1 }} / {{ slides.length }}
            </div>
          </div>
        </div>
      `,
    })
  }
}
