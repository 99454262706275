import Vue from 'vue'

export async function klaviyoBackInStockSubscribe(input) {
  const options = {
    subscribe_for_newsletter: false,
    g: null,
    platform: 'shopify',
    ...input,
  }
  const body = Object.entries(options).reduce((params, [key, value]) => {
    params.append(key, String(value))
    return params
  }, new URLSearchParams())

  try {
    const response = await fetch('https://a.klaviyo.com/onsite/components/back-in-stock/subscribe?', {
      method: 'POST',
      mode: 'cors',
      headers: {
        // 'Access-Control-Allow-Headers': '*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
      },
      body,
    })
    const { success, errors, message } = await response.json()
    if (!success && !!message) throw new Error(message)
    if (!success) throw new Error(errors.join(', '))
    return {
      success,
      message,
    }
  } catch (e) {
    console.log(e)
    throw e
  }
}

export default class Default {
  constructor() {
    Vue.component('Notify', {
      props: ['shop', 'product-id', 'variant-id'],
      data() {
        return {
          email: '',
          quantity: 1,
          acceptsMarketing: false,
          utcOffset: 0,
          message: null,
          errors: null,
        }
      },
      computed: {
        formattedErrors() {
          if (typeof this.errors === 'string') {
            return [this.errors]
          }
          if (!this.errors) return null
          return Object.keys(this.errors).map((key) => this.errors[key].join(', '))
        },
      },
      created() {
        const dateNow = new Date()
        this.utcOffset = dateNow.getTimezoneOffset()
      },
      methods: {
        async submit() {
          try {
            await klaviyoBackInStockSubscribe({
              a: 'KWpzrj',
              email: this.email,
              product: this.productId,
              variant: this.variantId,
            })
            this.message = 'Submitted!'
          } catch (e) {
            this.errors = [...this.errors, e.message]
          }
        },

        clearMessages() {
          this.message = null
          this.errors = null
        },
      },
      template: /*html*/ `
        <div class="notify-component">
          <form 
            @submit.prevent="submit"
            class="notify-form">
            <input 
              v-model="email"
              @input="clearMessages"
              type="email" 
              placeholder="Email"
              required
              class="email"
            />
            <button class="submit">
              <span class="a">Submit</span>
            </button>
          </form>
          <div 
            v-if="!!message"
            class="message">
            {{ message }}
          </div>
          <div 
            v-if="errors"
            class="errors">
            <div 
              v-for="error in formattedErrors"
              class="error"
            >
              {{ error }}
            </div>
          </div>
        </div>
      `,
    })
  }
}
