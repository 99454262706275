import Client from 'shopify-buy'

export const storefront = Client.buildClient({
  domain: 'loq-us.myshopify.com',
  storefrontAccessToken: '815dd00bb79d2857e70cba9fa23548d4',
})

export const resizeImage = (src, format = '') => {
  // Example format: 1024x1024_crop_center
  if (!src) return ''
  if (!src.includes('cdn.shopify')) return src
  const dotPosition = src.lastIndexOf('.')
  return `${src.substring(0, dotPosition)}_${format}${src.substring(dotPosition, src.length)}`
}
