import { showPrice } from './currency'

import _ from 'lodash'

export default {
  showTitle: (p) => {
    const split_title = p.title.split(' in ')
    return split_title[1]
      ? `<span class="name">${split_title[0]}</span> in <span class="variant">${split_title[1]}</span>`
      : `<span class="name">${p.title}</span>`
  },
  showPrice: (p) => {
    return showPrice({
      price: p.price,
      original: p.compare_at_price,
    })
  },
  showSizes: (product) => {
    const size_index = product.options.indexOf('Size')
    if (size_index === -1) return []
    return product.variants.map((v) => ({
      available: v.available,
      value: v.options[size_index],
    }))
  },
  showNote: (p) => {
    let message = false

    const is_low = !!_.find(p.variants, (v) => {
      return (
        v.inventory_management == 'shopify' &&
        v.inventory_policy == 'deny' &&
        v.inventory_quantity <= 3 &&
        v.inventory_quantity > 0
      )
    })

    if (p.compare_at_price > p.price) message = 'On Sale'
    if (is_low) message = 'Low in Stock'
    if (!p.available) message = 'Sold Out'

    return message ? message : ''
  },
  showMood: (state, view, product) => {
    const image_path = product.images.length > 1 ? product.images[1].src : product.images[0].src

    // image_path = view.app.showImage(image_path, view.products.getResize());
    return image_path
    // return { "background-image": `url(${image_path}` };
  },
  getResize: () => {
    return '900x1200_crop_center'
  },
  formatProducts: (d) => {},
  showAvailability: (p) => {
    const colours = _.filter(p.variants, (v) => {
      return v.available
    })

    const pluralise = colours && colours.length > 1 ? 'colors' : 'color'

    return colours && colours.length > 1 ? `Available in ${colours.length} ${pluralise}` : ''
  },
}
