export default class Mouse {
  // Which HTML element is the target of the event
  mouseTarget(e) {
    let targ
    if (!e) e = window.event
    if (e.target) targ = e.target
    else if (e.srcElement) targ = e.srcElement
    if (targ.nodeType == 3)
      // defeat Safari bug
      targ = targ.parentNode
    return targ
  }

  // Mouse position relative to the document
  // From http://www.quirksmode.org/js/events_properties.html
  mousePositionDocument(e) {
    let posx = 0
    let posy = 0
    if (!e) {
      e = window.event
    }
    if (e.pageX || e.pageY) {
      posx = e.pageX
      posy = e.pageY
    } else if (e.clientX || e.clientY) {
      posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft
      posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop
    }
    return {
      x: posx,
      y: posy,
    }
  }
}
