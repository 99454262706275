<template>
  <video loop muted playsinline autoplay class="video-loader">
    <source :src="url" type="video/mp4" />
  </video>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.video-loader {
  width: 100%;
}
</style>
