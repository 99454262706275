<template>
  <div class="newsletter">
    <div class="newsletter__inner">
      <transition name="fade" mode="out-in">
        <div v-if="message" key="message" class="message newsletter__message" v-html="message" />
        <div v-else-if="note" key="note" class="newsletter__cta" v-html="note" />
      </transition>
      <form class="newsletter__form" method="get" autocomplete="off" @submit.prevent="onSubmit">
        <div class="newsletter__email-container">
          <label
            :key="placeholder"
            for="newsletter__email"
            class="newsletter__label"
            :class="{
              'newsletter-Label_focus': inputFocused,
              newsletter__label_hidden: email,
            }"
            v-text="placeholder"
          />
          <input
            id="newsletter__email"
            v-model="email"
            class="newsletter__email"
            :class="{ newsletter__email_active: inputFocused || email }"
            type="email"
            :placeholder="'Email Address'"
            required
            aria-label="Email Address"
          />
        </div>
        <button
          aria-label="Submit Signup"
          class="newsletter__submit"
          :class="{ newsletter__submit_visible: inputFocused || email }"
        >
          <transition name="fade" mode="out-in">
            <span :key="submitText" class="newsletter__submitText" v-text="submitText" />
          </transition>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { subscribe } from 'klaviyo-subscribe'
import { mapMutations } from 'vuex'

export default {
  props: {
    action: {
      type: String,
      required: true,
    },
    note: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      email: '',
      inputFocused: false,
      listId: 'NmqcUq',
      message: '',
      messageTimeout: null,
      submitting: false,
    }
  },

  computed: {
    placeholder() {
      return this.inputFocused ? 'Email Address' : 'Newsletter'
    },
    submitText() {
      return this.submitting ? 'Submitting' : 'Submit'
    },
  },

  watch: {
    message(newMessage) {
      if (this.messageTimeout) {
        this.clearMessageTimeout()
      }
      this.messageTimeout = window.setTimeout(() => (this.message = ''), 3500)
    },
  },

  methods: {
    ...mapMutations(['setNotificationMessage']),

    onEmailFocus() {
      this.inputFocused = true
    },
    onEmailFocusOut() {
      this.inputFocused = false
    },
    async onSubmit() {
      try {
        const response = await subscribe(this.listId, this.email)
        if (response.success) {
          this.message = 'Thank you for signing up!'
          this.email = ''
        } else {
          throw new Error(response.errors[0])
        }
      } catch (exception) {
        this.message = `${exception.message}`
      }
    },
    clearMessage() {
      this.message = ''
    },
    clearMessageTimeout() {
      window.clearTimeout(this.messageTimeout)
      this.messageTimeout = null
    },
  },
}
</script>

<style lang="scss">
@import '~styles/frontend/variables';
@import '~styles/frontend/mixins';

.newsletter {
  @include cx-m;
  @include uppercase;

  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid;
  text-align: center;
  padding: 20px;
  // @include displaySans("s");

  // position: relative;
  // padding-bottom: 30px;

  &__cta {
    text-align: center;
  }

  &__form {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__email-container {
    position: relative;
    width: 160px;
    font-size: inherit;
    // margin-right: 10px;
  }

  &__label {
    display: none;
  }

  &__email {
    width: 100%;
    padding-right: 10px;
    background: none;
    // border-bottom: 1px solid transparent;
    // transition: border-color var(--transition-color-timing);

    &::placeholder {
      @include uppercase;

      color: #7f756b;
    }
  }

  &__submit {
    @include uppercase;
    // border-bottom: 1px solid;
    // opacity: 0;
    // transition: opacity var(--transition-color-timing);
    // pointer-events: none;

    // &:focus,
    // &_visible {
    //   opacity: 1;
    //   pointer-events: auto;
    // }
  }
}
</style>
