import Base from '../../base'
import Vue from 'vue'
import $ from 'jquery'
import _ from 'lodash'

export default class Default extends Base {
  init() {
    //Bail unless we are on product page
    if (this.d.template.indexOf('product') == -1) return

    //Initialise

    this.bus.$on('vue/loaded', (d) => {
      this.initRelated()
      this.bus.$on('window/resize', (d) => {
        this.onResize(d)
      })
    })

    this.bus.$on('product/buy', (d) => {
      this.buy(d)
    })
    this.bus.$on('cart/item/adding', (d) => {
      this.cartItemAdding()
    })
    this.bus.$on('cart/item/added', (d) => {
      this.cartItemAdded()
    })
  }

  buy(d) {
    const variant_id = this.s.product.selected_variant.id
    const quantity = this.s.product.selected_quantity

    const isPreorder =
      this.s.product.selected_product.metafields.preorder.enabled ||
      this.s.product.selected_variant.metafields.preorder.enabled

    let valid = true
    if (typeof variant_id == 'undefined') valid = false

    if (valid) {
      this.s.product.error = false
      this.bus.$emit('cart/item/add', {
        id: variant_id,
        quantity: quantity,
        properties: { ...(isPreorder && { _preorder: true }) },
      })
    } else {
      this.s.product.error = 'Please make a selection'
    }
  }

  cartItemAdding() {
    this.s.cart.current_action = 'adding'
  }

  cartItemAdded() {
    this.s.cart.current_action = 'added'
    this.s.header.cart_active = true
    setTimeout(() => {
      this.s.cart.current_action = ''
    }, 2000)
  }

  onResize() {
    const product = document.querySelector('.product')
    const $content = $('[data-ref="content"]')
    const $images = $('[data-ref="image"]')

    if ($images.length > 0) {
      this.s.product.images = _.map($images, (v, idx) => {
        const $v = $(v)
        const caption = $v.attr('data-caption')
        const offset = $v.offset()
        const top = offset ? offset.top : 0
        const height = $v.height()

        return {
          caption,
          top,
          height,
          bottom: top + height,
        }
      })
    } else {
      console.warn('No product images found')
      this.s.product.images = []
    }

    this.s.product.content_bottom = $content.offset().top + $content.height()
    this.s.product.product_bottom = product.offsetTop + product.offsetHeight
  }

  initRelated() {
    //Search for related products based on tag
    const tags = this.d.product.tags

    const tag = _.filter(tags, (t) => {
      return t.indexOf('related-') !== -1
    })[0]

    if (!tag) return //No point searching without tag
    const handle = tag.replace('related-', '')

    this.u.shopify.products.collection({
      handle: handle,
      callback: (products) => {
        //Filter out the current product, because we don't need it
        const related = []
        if (products.length >= 1) {
          //Remove current product from list
          this.s.product.related = this.formatRelated(products)
        }
      },
    })
  }

  formatRelated(products, include_self = false) {
    return _.chain(products)
      .filter((p) => {
        return include_self ? true : p.id !== this.d.product.id
      })
      .map((p) => {
        p.image_size = this.v.products.getResize(p)
        return p
      })
      .shuffle()
      .slice(0, 4)
      .value()
  }
}
