import Base from '../../base'
import { postData } from '../../lib/fetch'

export default class Default extends Base {
  init() {
    this.bus.$on('cart/item/add', (d) => this.add(d))
    this.bus.$on('cart/item/remove', (d) => this.remove(d))
    this.bus.$on('cart/item/update', (d) => this.update(d))
    this.bus.$on('cart/update', (d) => this.updateCart(d))
  }

  async add(d) {
    this.bus.$emit('cart/item/adding')
    const response = await postData('/cart/add.js', { quantity: 1, ...d })

    if (!response.status) {
      this.refresh('cart/item/added')
      this.m.overlay.setOverlay(this.s, 'cart')
      return
    }

    this.b.vm.$store.commit('setCartError', response.description)
    this.s.cart.current_action = 'Error'

    setTimeout(() => {
      this.b.vm.$store.commit('setCartError', '')
      this.s.cart.current_action = ''
    }, 2500)
  }

  remove(d) {
    const id = d.id
    this.bus.$emit('cart/item/removing')
    this.u.shopify.cart.update({
      data: {
        updates: {
          [id]: 0,
        },
      },
      callback: (_d) => {
        this.refresh('cart/item/removed')
      },
    })
  }

  update(input) {
    const { id, quantity } = input
    this.bus.$emit('cart/item/updating')
    this.u.shopify.cart.update({
      data: {
        updates: {
          [id]: quantity,
        },
      },
      callback: (d) => {
        this.refresh('cart/item/updated')
      },
    })
  }

  updateAttributes(attributes) {
    this.bus.$emit('cart/item/updating')
    this.u.shopify.cart.update({
      data: {
        attributes: attributes,
      },
      callback: (d) => {
        this.refresh('cart/item/updated')
      },
    })
  }

  updateCart(d) {
    if (d.currency) {
      this.updateCurrency(d.currency)
    }
    // Add other cart update logic here if needed
  }

  updateCurrency(currency) {
    this.bus.$emit('cart/currency/updating')
    this.u.shopify.cart.update({
      data: {
        currency: currency,
      },
      callback: (d) => {
        this.refresh('cart/currency/updated')
      },
    })
  }

  refresh(path) {
    //Refresh the cart contents
    this.u.shopify.cart.get({
      callback: (d) => {
        this.d.cart = d
        this.bus.$emit(path)
      },
    })
  }
}
