import Base from '../../base'

function currencyToCountry(currency) {
  const currencyToCountryMap = {
    USD: 'US',
    EUR: 'DE', // Using Germany as a default for Euro
    GBP: 'GB',
    JPY: 'JP',
    AUD: 'AU',
    CAD: 'CA',
    CHF: 'CH',
    CNY: 'CN',
    SEK: 'SE',
    NZD: 'NZ',
    // Add more mappings as needed
  };

  if (currencyToCountryMap.hasOwnProperty(currency)) {
    return currencyToCountryMap[currency];
  } else {
    console.warn(`No country mapping found for currency ${currency}`);
    return 'US'; // Default to US if there's no mapping
  }
}

export default class Default extends Base {
  init() {
    this.bus.$on('currency/set', (code) => this.set(code))
  }

  set(currency) {
    if (currency) {
      const form = document.createElement('form')
      form.method = 'post'
      form.action = '/localization'
      form.setAttribute('accept-charset', 'UTF-8')
      form.setAttribute('enctype', 'multipart/form-data')
      // form.id = 'localization_form'
      // form.classList.add('shopify-localization-form')

      const countryCode = currencyToCountry(currency)

      console.log({ countryCode })

      const inputs = [
        { name: 'form_type', value: 'localization' },
        { name: 'utf8', value: '✓' },
        { name: '_method', value: 'put' },
        { name: 'return_to', value: window.location.pathname },
        { name: 'country_code', value: countryCode },
      ]

      inputs.forEach((input) => {
        const hiddenInput = document.createElement('input')
        hiddenInput.type = 'hidden'
        hiddenInput.name = input.name
        hiddenInput.value = input.value
        form.appendChild(hiddenInput)
      })

      document.body.appendChild(form)
      form.submit()
    } else {
      console.warn('Currency data not available in window._data')
    }
  }
}
