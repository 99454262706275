export default function () {
  // Select first available variant and its options
  const variants = this.d.product.variants || []
  const initialVariant = variants.filter((v) => v.available)[0] || variants[0]

  return {
    active_options: '',
    selected_variant: initialVariant,
    selected_quantity: 1,
    selection_group: '',
    product_group_menu: false,
    selected_product:
      this.d.product_group?.find((p) => p.handle === this.d.product.handle) || this.d.product,
    selected_options: initialVariant?.options,
    selected_slide: 0,
    related: [],
    notify_email: '',
    message: '',
    error: false,
    images: [],
    content_bottom: -1,
    product_bottom: -1,
  }
}
