import Base from '../../base'

export default class Default extends Base {
  // init() {
  //   this.bus.$on("vue/loaded", d => {
  //     this.tests(d);
  //   });
  //   this.bus.$on("scroll/top", d => {
  //     this.scrollToTop(d);
  //   });
  //   this.bus.$on("submenu/click", d => {
  //     this.submenuClick(d);
  //   });
  //   //Swatch overlay
  //   this.bus.$on("swatches", s => {
  //     this.s.overlay.current = "swatches";
  //   });
  // }
  // tests() {
  //   if (window.matchMedia("(any-pointer: fine)").matches) {
  //     this.s.app.has_mouse = true;
  //   }
  // }
  // scrollToTop(d) {
  //   $("body").velocity("scroll", { offset: "0", mobileHA: false });
  // }
  // submenuClick(d) {
  //   //open if on desktop, else do nothing
  //   var has_mouse = this.s.app.has_mouse;
  //   if (!has_mouse) {
  //     d.evt.preventDefault();
  //   }
  // }
}
