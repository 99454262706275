import _ from 'lodash'

export default {
  formatLocation(stockists_data, stockists_state, location) {
    const locationObject = _.groupBy(stockists_data, location)
    return Object.keys(locationObject).map((l) => ({
      title: l,
      sublocations: locationObject[l],
    }))
  },
}
