import Vue from 'vue'

import _ from 'lodash'

export default {
  selectOptionGroup(state, data, option, option_index) {
    state.product.selected_product = _.clone(data.product_group[option_index])
    state.product.selected_options[0] = option

    this.updateSelectedVariant(state)
    this.updateAffirm(state)
  },

  selectOption(state, data, option, ov, ov_idx) {
    //Update selected options
    // var selected_options = state.product.selected_options;
    // selected_options[option.type_index] = ov;
    // state.product.selected_options = []; // Force refresh
    const newOptions = [...state.product.selected_options]
    newOptions[option.type_index] = ov
    Vue.set(state.product, 'selected_options', newOptions)

    //Format selected options to match the way shopifies variant.options works
    const variant_options = []
    _.each(state.product.selected_options, (ov, idx) => {
      variant_options[idx] = ov
    })

    //Attempt to find variant that the options match
    const selected_variant = _.find(state.product.selected_product.variants, (v) => {
      const clean_options = _.map(v.options, (vo) => {
        return vo
      })
      return _.isEqual(variant_options, clean_options)
    })

    //Update variant if one is found and not the same as old one
    if (selected_variant) {
      state.product.selected_variant = selected_variant
    }

    this.updateAffirm(state)
  },

  updateSelectedVariant(state) {
    if (!state.product.selected_product) return
    const variants = state.product.selected_product.variants
    const selected = variants.filter((v) => v.available)[0] || variants[0]

    state.product.selected_variant = _.clone(selected)
    state.product.selected_options = _.clone(selected.options)
  },

  updateAffirm(state) {
    // Affirm script
    const affirmElement = document.querySelector('.affirm-as-low-as')
    const newPrice =
      state.product.selected_variant.price ||
      state.product.selected_product.price ||
      window._data.product.price

    if (affirmElement) affirmElement.setAttribute('data-amount', newPrice)

    //Call affirm.ui.refresh to re-render the promotional messaging componenets
    window.affirm.ui.refresh()
  },
}
