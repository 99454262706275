import '~styles/theme.scss'

import Vue from 'vue'
import Utils from './utils'
import Feat from './feat/feat'
import store from './store'
import Banner from './components/common/Banner.vue'
import CollectionFilter from './components/collection/collection-filter.vue'
import CollectionBlock from './components/collection/collection-block.vue'
import FeaturedCollection from './components/collection/featured-collection.vue'
import ImageLoader from './components/helpers/image-loader.vue'
import IntersectionWrapper from './components/helpers/intersection-wrapper.vue'
import Newsletter from './components/common/newsletter.vue'
import ProductTitle from './components/helpers/product-title.vue'
import VideoLoader from './components/common/video-loader.vue'
import VideoStream from './components/common/video-stream.vue'
// import Togglable from './components/common/togglable'

// import "core-js/stable";
// import "regenerator-runtime/runtime";
import smoothscroll from 'smoothscroll-polyfill'

smoothscroll.polyfill()

Vue.filter('accentuateImage', (src, options = '') => {
  if (!src) return ''
  return `//images.accentuate.io?c_options=${options}&shop=loq-us.myshopify.com&image=${src}`
})

Vue.component('Banner', Banner)
Vue.component('CollectionFilter', CollectionFilter)
Vue.component('CollectionBlock', CollectionBlock)
Vue.component('FeaturedCollection', FeaturedCollection)
Vue.component('ImageLoader', ImageLoader)
Vue.component('IntersectionWrapper', IntersectionWrapper)
Vue.component('Newsletter', Newsletter)
Vue.component('ProductTitle', ProductTitle)
Vue.component('VideoLoader', VideoLoader)
Vue.component('VideoStream', VideoStream)

export default class App {
  constructor(d) {
    this.data = d // Data provided on-page via script tags
    this.state = {} // State used on this page
    this.store = {} // State shared between pages
    this.view = {} // Functions that display state
    this.mutator = {} // Functions that mutate state
    this.event = {} // Event listeners/publishers

    // Create event bus
    this.bus = new Vue()

    // Utility functions
    this.utils = new Utils(this)
    this.feat = new Feat(this)

    // Create Vue instance
    this.vm = new Vue({
      el: '#app',

      components: {
        Aperture: () => import('./components/Aperture.vue'),
        QuantitySelect: () => import('./components/quantity-select.vue'),
        BlogFeature: () => import('./components/blog-feature.vue'),
        NewsletterPopup: () => import('./components/newsletter-popup.vue'),
        NewsletterPopupCard: () => import('./components/newsletter-popup-card.vue'),
        ProductBlock: () => import('./components/collection/product-block.vue'),
        ProductPreorderInfo: () => import('./components/product/ProductPreorderInfo.vue'),
        ProductPrice: () => import('./components/product/ProductPrice.vue'),
        Signup: () => import('./components/signup.vue'),
      },

      store: store,

      data: () => {
        return {
          d: this.data,
          s: this.state,
          v: this.view,
          m: this.mutator,
          ls: this.store, //local storage
          bus: this.bus,
          Shopify: window.Shopify, // Make Shopify available to the Vue instance
        }
      },

      delimiters: ['{~', '~}'],

      computed: {
        currentCurrency() {
          return this.s.currency?.code || window.Shopify?.currency?.active || 'USD'
        },
        restockable() {
          return !this.s.product?.selected_product?.tags
            ?.map((tag) => tag.toLowerCase())
            .includes('no-restock')
        },
      },

      watch: {
        currentCurrency(newCurrency) {
          this.$store.commit('setCurrentCurrency', newCurrency)
        },
      },

      methods: {
        log: (log) => {
          console.log(log)
        },
        emit: (path, data = {}) => {
          console.log('emit', path)
          this.bus.$emit(path, data)
        },
        instance: function () {
          return this
        },
      },
    })

    this.bus.$emit('vue/loaded', {})
  }
}

window.app = new App(window._data)
