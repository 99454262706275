import _ from 'lodash'

export default {
  showCounter(s) {
    const window_height = s.window.resize.height.current
    const offset_top = s.window.scroll.top.current
    const images = _.clone(s.lookbook.images).reverse()

    const image = _.find(images, (v) => {
      const window_half = offset_top + window_height / 2
      return window_half > v.top
    })

    if (!image) return ''

    const current = image.idx + 1
    const total = images.length

    return `${current}/${total}`
  },
}
