const dataDefaults = {
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
}

export const getData = async (url, data, overrides) => {
  const config = Object.assign(
    dataDefaults,
    {
      method: 'GET',
      body: JSON.stringify(data),
    },
    overrides
  )
  const response = await fetch(url, config)
  return response.json()
}

export const postData = async (url, data, overrides) => {
  const config = Object.assign(
    dataDefaults,
    {
      method: 'POST',
      body: JSON.stringify(data),
    },
    overrides
  )
  const response = await fetch(url, config)
  return response.json()
}
