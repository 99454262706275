import _ from 'lodash'
import $ from 'jquery'

export default {
  showCursorStyle: (data, state, view) => {
    const image = state.index.cursor

    if (!image) return {}

    const x = `${image.x + 5}px`
    const y = `${image.y + 5}px`

    const position = {
      left: 0, //`${image.x + 5}px`,
      top: 0, //`${image.y - 10}px`
      transform: `translateX(${x}) translateY(${y})`,
    }

    const current = state.index.current_index
    const src = data.index.cursors[current]
    const background = { 'background-image': `url('${src}')` }

    return _.merge(position, background)
  },
  showImageStyle: (data, state, key) => {
    const current = state.index.current_index
    const src = data.index[key][current]

    return `background-image:url('${src}')`
  },
  showImageCaption: (data, state, key) => {
    const current = state.index.current_index
    const caption = data.index.captions[current]

    return caption
  },
  getImageUrl: (data, state, key) => {
    const current = state.index.current_index
    const url = data.index.urls[current]

    return url
  },
  showLogoStyle: (d, s) => {
    if (!d.template.match(/^index/)) return
    const is_mobile = window.matchMedia('(max-width: 800px)').matches
    const header_height = is_mobile ? 65 : 75
    const overlay_open = !!s.overlay.current
    const scrollPercent = s.index.landing_scroll_percent / 100
    const header_spacing = s.window.resize.height.current / 2 - header_height // 75 = header height
    const scaleMultiplier = is_mobile ? 2.5 : 4
    const scale = overlay_open ? 1 : scaleMultiplier - (scaleMultiplier - 1) * scrollPercent
    const padding = overlay_open ? 0 : header_spacing - header_spacing * scrollPercent

    return {
      transform: `scale(${scale})`,
      'padding-bottom': `${padding}px`,
    }
  },
  showLandingTextStyle(s) {
    const is_mobile = window.matchMedia('(max-width: 800px)').matches
    if (is_mobile) return
    const scrollPercent = s.index.landing_scroll_percent / 100
    const header_spacing = s.window.resize.height.current / 2
    const top = header_spacing - (header_spacing - 75) * scrollPercent
    return { top: `${top}px` }
  },
  showLandingImageStyle(state) {
    const overlay = state.overlay.current
    const zIndex = state.index.landing_content_overlay && !overlay ? 1200 : 0
    const opacity = !!overlay ? 0.5 : 1
    return { 'z-index': zIndex, opacity }
  },
  scrollDown() {
    if (window.matchMedia('(max-width: 800px)').matches) return
    const blockTop = document.querySelectorAll('.block')[1].offsetTop - (75 - 1)
    $('body').velocity('scroll', { offset: blockTop, duration: 1000 })
  },
}
