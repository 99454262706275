export default class Helpers {
  slugify(text) {
    return text
      .toLowerCase()
      .replace(/[^\w ]+/g, '')
      .replace(/ +/g, '-')
  }

  getDocumentWidth() {
    return Math.max(
      document.documentElement['clientWidth'],
      document.body['scrollWidth'],
      document.documentElement['scrollWidth'],
      document.body['offsetWidth'],
      document.documentElement['offsetWidth']
    )
  }

  getDocumentHeight() {
    return Math.max(
      document.documentElement['clientHeight'],
      document.body['scrollHeight'],
      document.documentElement['scrollHeight'],
      document.body['offsetHeight'],
      document.documentElement['offsetHeight']
    )
  }

  getPosition(element) {
    let xPosition = 0
    let yPosition = 0

    while (element) {
      xPosition += element.offsetLeft - element.scrollLeft + element.clientLeft
      yPosition += element.offsetTop - element.scrollTop + element.clientTop
      element = element.offsetParent
    }

    return { x: xPosition, y: yPosition }
  }

  zeroPad(n, width, z) {
    z = z || '0'
    n = n + ''
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
  }

  getImageMeta(opts) {
    const img = new Image()
    img.addEventListener('load', function () {
      opts.onLoad({
        width: this.naturalWidth,
        height: this.naturalHeight,
      })
    })
    img.src = opts.src
  }

  console() {
    ;(function () {
      let method
      const noop = function () {}
      const methods = [
        'assert',
        'clear',
        'count',
        'debug',
        'dir',
        'dirxml',
        'error',
        'exception',
        'group',
        'groupCollapsed',
        'groupEnd',
        'info',
        'log',
        'markTimeline',
        'profile',
        'profileEnd',
        'table',
        'time',
        'timeEnd',
        'timeStamp',
        'trace',
        'warn',
      ]
      let length = methods.length
      const console = (window.console = window.console || {})

      while (length--) {
        method = methods[length]

        // Only stub undefined methods.
        if (!console[method]) {
          console[method] = noop
        }
      }
    })()
  }

  requestAnimationFrame() {
    let lastTime = 0
    const vendors = ['webkit', 'moz']
    for (let x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
      window.requestAnimationFrame = window[vendors[x] + 'RequestAnimationFrame']
      window.cancelAnimationFrame =
        window[vendors[x] + 'CancelAnimationFrame'] ||
        window[vendors[x] + 'CancelRequestAnimationFrame']
    }

    if (!window.requestAnimationFrame) {
      window.requestAnimationFrame = function (callback, element) {
        const currTime = new Date().getTime()
        const timeToCall = Math.max(0, 16 - (currTime - lastTime))
        const id = window.setTimeout(function () {
          callback(currTime + timeToCall)
        }, timeToCall)
        lastTime = currTime + timeToCall
        return id
      }

      if (!window.cancelAnimationFrame) {
        window.cancelAnimationFrame = function (id) {
          clearTimeout(id)
        }
      }
    }
  }
}
