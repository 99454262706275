import Base from './base'
import Shopify from './utils/shopify'
import Window from './utils/window'
import Mouse from './utils/helpers/mouse'
import Helpers from './utils/helpers/helpers'
import Preload from './utils/preload/preload'

//Vue
import Components from './utils/components'
import Directives from './utils/directives'

export default class Default {
  constructor(main) {
    this.shopify = new Shopify(main)
    this.mouse = new Mouse(main)
    this.helpers = new Helpers(main)
    this.window = new Window(main, this)
    this.preload = new Preload(main)

    //Vue
    new Components(main)
    new Directives(main)

    //Assign save data to main class
    // this.storage    = window.store; //store.js plugin
    // var local_store = this.storage.get('local');
    // main.store      = (typeof(local_store) === 'undefined') ? {} : local_store;
  }
}
