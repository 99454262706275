<template>
  <div class="featured-collection">
    <slot />

    <ProductBlock
      v-for="product in featuredProducts"
      :key="product.title"
      :product="product"
      class="featured-block"
    />
  </div>
</template>

<script>
import { shuffle } from 'lodash-es'
import ProductBlock from './product-block.vue'

export default {
  name: 'FeaturedCollection',

  components: {
    ProductBlock,
  },

  props: {
    collection: {
      type: Object,
      default() {
        return {}
      },
    },

    products: {
      type: Array,
      required: true,
    },
    shuffle: {
      type: Boolean,
      default: false,
    },
    length: {
      type: Number,
      default: Infinity,
    },
  },

  computed: {
    featuredProducts() {
      const shuffled = this.shuffle ? shuffle(this.products) : this.products
      return shuffled.slice(0, this.length)
    },
  },
}
</script>
